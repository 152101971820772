import { Route, Redirect } from 'react-router-dom';
import React, { Suspense, lazy, Fragment } from 'react';
import Loader from 'react-loaders'
import {ToastContainer} from 'react-toastify';
import { connect } from 'react-redux'

import PrivateRoute from '../../authRoute'

import { requestData } from '../../helper/request'
import { init_auth, clear_auth } from "../../reducers/Auth"

const Dashboards = lazy(() => import('../../View/Dashboards'));
const Monitoring = lazy(() => import('../../View/MonitoringLimit'));
const CustomerWP = lazy(() => import('../../View/MonitoringWrongPassword'));
const CustomerWPin = lazy(() => import('../../View/MonitoringWrongPin'));
const Blacklist = lazy(() => import('../../View/Blacklist'));
const BlacklistIP = lazy(() => import('../../View/BlacklistIP/ListIP'));
const CustomerR = lazy(()=> import('../../View/CustomerReset'))
const Suspicious = lazy(() => import('../../View/ReportSpsTrx'));
const Delivery = lazy(() => import('../../View/Delivery'));
const Customers = lazy(() => import('../../View/Customers'));
const Whitelabel = lazy(() => import('../../View/Whitelabel'));
const Merchants = lazy(() => import('../../View/Merchants'));
const Promo = lazy(() => import('../../View/PromoEcommerce'));
const Message = lazy(() => import('../../View/Message'));
const Notification = lazy(() => import('../../View/Notification'));
const KritikSaran = lazy(() => import('../../View/KritikSaran'));
const CustomerVerifikasi = lazy(() => import('../../View/CustomerVerifikasi'));
const PPOB = lazy(() => import('../../View/PPOB'));
const TF = lazy(() => import('../../View/TransferUang'));
const ISimpel = lazy(() => import('../../View/ISimpel'));
const ECommerce = lazy(() => import('../../View/Marketplace'));
const WebTopup = lazy(() => import('../../View/WebTopup'));
const Digipos = lazy(() => import('../../View/Digipos'));
const TiketKereta = lazy(() =>import('../../View/TiketKereta'));
const Content = lazy(() => import('../../View/Content'));
const Wallet = lazy(() => import('../../View/Wallet'));
const Withdraw = lazy(() => import('../../View/PengajuanWithdraw'));
const ProductVoucher = lazy(() => import('../../View/ProductVoucher'));
const ProductInject = lazy(() => import('../../View/ProductInject'));
const PaketData = lazy(() => import('../../View/ProductPaketData'));
const Users = lazy(() => import('../../View/Users'));
const Report = lazy(() => import('../../View/Report'));
const Shipping = lazy(() => import('../../View/Shipping'));
const Setting = lazy(() => import('../../View/Setting'));
const UserLogin = lazy(() => import('../../View/UserPages/Login'));
const DealerMarkup = lazy(() => import('../../View/DealerMarkup'));
const Kupon = lazy(() => import('../../View/PromoPpob'));
const Otp = lazy(() => import('../../View/Otp'));
const CHPassword = lazy(() => import('../../View/CustomerHistoryPass'));
const CHPin = lazy(() => import ('../../View/CustomerHistoryPin'));
const Request = lazy(() => import ('../../View/RequestLimit'));
const Cuanku = lazy(() => import('../../View/Cuanku'));
const BroadCastMessage = lazy(() => import('../../View/CustomerService'));

class AppMain extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            active: false,
            data : []
        };
    }

    componentDidMount() {
        const Auth = localStorage.getItem('BP-S-Auth');
        const AuthKey = localStorage.getItem('BP-S-Auth-Key');
        const AuthUser = localStorage.getItem('BP-S-Auth-User');

        if(Auth != null && AuthKey != null && AuthUser != null)
            this.props.init_auth(Auth, AuthKey, JSON.parse(AuthUser))
    }

    request = async (action, data, method) => {
        if(action && data) {
            const AuthKey = localStorage.getItem('BP-S-Auth-Key');
            var AuthUser = JSON.parse(localStorage.getItem('BP-S-Auth-User'))

            data = { api_token: AuthKey, auth_level: AuthUser.user_level, ...data }

            return requestData(action, data, method)
                .then(response => {
                    if(response.auth != undefined && !response.auth)
                        this.props.clear_auth()

                    return response
                })
        }
    };

    render() {
        return (
            <Fragment>
                {/* Dashboards */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-grid-beat"/>
                            </div>
                            <h6 className="mt-3">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute
                        path="/dashboards"
                        component={Dashboards}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-grid-beat"/>
                            </div>
                            <h6 className="mt-3">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute
                        path="/report-suspicious"
                        component={Suspicious}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-grid-beat"/>
                            </div>
                            <h6 className="mt-3">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute
                        path="/blacklist-IP"
                        component={BlacklistIP}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-grid-beat"/>
                            </div>
                            <h6 className="mt-3">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute
                        path="/chpassword"
                        component={CHPassword}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-grid-beat"/>
                            </div>
                            <h6 className="mt-3">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute
                        path="/chpin"
                        component={CHPin}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-grid-beat"/>
                            </div>
                            <h6 className="mt-3">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute
                        path="/pengajuan-limit"
                        component={Request}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-grid-beat"/>
                            </div>
                            <h6 className="mt-3">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute
                        path="/limit-view"
                        component={Monitoring}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-grid-beat"/>
                            </div>
                            <h6 className="mt-3">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute
                        path="/wrong-password"
                        component={CustomerWP}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-grid-beat"/>
                            </div>
                            <h6 className="mt-3">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute
                        path="/wrong-pin"
                        component={CustomerWPin}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-grid-beat"/>
                            </div>
                            <h6 className="mt-3">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute
                        path="/blacklist"
                        component={Blacklist}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-grid-beat"/>
                            </div>
                            <h6 className="mt-3">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute
                        path="/customer-reset"
                        component={CustomerR}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-grid-beat"/>
                            </div>
                            <h6 className="mt-3">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute
                        path="/otp"
                        component={Otp}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-grid-beat"/>
                            </div>
                            <h6 className="mt-3">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute
                        path="/delivery/price"
                        component={Delivery}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>

                {/* Customers */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse-rise"/>
                            </div>
                            <h6 className="mt-5">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute
                        path="/customers"
                        component={Customers}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>

                {/* Customers verifikasi */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse-rise"/>
                            </div>
                            <h6 className="mt-5">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute
                        path="/verifikasi"
                        component={CustomerVerifikasi}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>

                {/* Promo */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse-rise"/>
                            </div>
                            <h6 className="mt-5">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute
                        path="/promo"
                        component={Promo}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>

                {/* Whitelabel */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse-rise"/>
                            </div>
                            <h6 className="mt-5">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute
                        path="/whitelabel"
                        component={Whitelabel}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>

                {/* Merchants */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse-rise"/>
                            </div>
                            <h6 className="mt-5">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute
                        path="/merchants"
                        component={Merchants}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>

                {/* Message */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse-rise"/>
                            </div>
                            <h6 className="mt-5">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute
                        path="/message"
                        component={Message}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>

                {/* Notification */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse-rise"/>
                            </div>
                            <h6 className="mt-5">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute
                        path="/notification"
                        component={Notification}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>{/* Notification */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse-rise"/>
                            </div>
                            <h6 className="mt-5">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute
                        path="/broadcast"
                        component={BroadCastMessage}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>

                {/* KritikSaran */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse-rise"/>
                            </div>
                            <h6 className="mt-5">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute
                        path="/kritiksaran"
                        component={KritikSaran}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>

                {/* Transfer Uang */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse-rise"/>
                            </div>
                            <h6 className="mt-5">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute
                        path="/tf"
                        component={TF}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>

                {/* PPOB */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse-rise"/>
                            </div>
                            <h6 className="mt-5">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute
                        path="/ppob"
                        component={PPOB}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>

                {/*E-Commerce*/}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse-rise"/>
                            </div>
                            <h6 className="mt-5">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute
                        path="/marketplace"
                        component={ECommerce}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>

                {/*ISimpel*/}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse-rise"/>
                            </div>
                            <h6 className="mt-5">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute
                        path="/isimpel"
                        component={ISimpel}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>

                {/*Web Topup*/}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse-rise"/>
                            </div>
                            <h6 className="mt-5">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute
                        path="/web-topup"
                        component={WebTopup}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>

                {/*Digipos*/}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse-rise"/>
                            </div>
                            <h6 className="mt-5">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute
                        path="/digipos"
                        component={Digipos}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>

                {/*Tiket Kereta*/}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse-rise"/>
                            </div>
                            <h6 className="mt-5">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute
                        path="/kereta"
                        component={TiketKereta}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>

                {/*Product Voucher*/}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse-rise"/>
                            </div>
                            <h6 className="mt-5">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute
                        path="/product-voucher"
                        component={ProductVoucher}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>

                {/*Product Inject*/}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse-rise"/>
                            </div>
                            <h6 className="mt-5">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute
                        path="/product-inject"
                        component={ProductInject}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>

                {/*Product Paket Data*/}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse-rise"/>
                            </div>
                            <h6 className="mt-5">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute
                        path="/product-paket-data"
                        component={PaketData}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>

                {/*Content*/}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse-rise"/>
                            </div>
                            <h6 className="mt-5">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute
                        path="/Content"
                        component={Content}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>

                {/*Wallet*/}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse-rise"/>
                            </div>
                            <h6 className="mt-5">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute
                        path="/Wallet"
                        component={Wallet}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>

                {/*Withdraw*/}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse-rise"/>
                            </div>
                            <h6 className="mt-5">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute
                        path="/Withdraw"
                        component={Withdraw}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>

                {/*Shipping*/}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse-rise"/>
                            </div>
                            <h6 className="mt-5">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute
                        path="/shipping"
                        component={Shipping}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>

                {/*Report*/}
                <Suspense fallback={

                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse-rise"/>
                            </div>
                            <h6 className="mt-5">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>

                    <PrivateRoute
                        path="/report"
                        component={Report}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>

                </Suspense>

                {/*Seting*/}
                <Suspense fallback={

                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse-rise"/>
                            </div>
                            <h6 className="mt-5">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute
                        path="/setting"
                        component={Setting}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>

                {/*User*/}

                <Suspense fallback={

                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse-rise"/>
                            </div>
                            <h6 className="mt-5">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute
                        path="/users"
                        component={Users}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>

                {/* Users Pages*/}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse-rise"/>
                            </div>
                            <h6 className="mt-5">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <Route
                        path="/logincms"
                        render={(props) => {
                            return <UserLogin
                                request={(action, data, method = 'GET') => requestData(action, data, method)}
                                {...props}/>
                        }}/>
                </Suspense>

                <Route exact path="/" render={() => <Redirect to="/logincms"/> }/>

                {/* Whitelabel */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse-rise"/>
                            </div>
                            <h6 className="mt-5">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute
                        path="/dealer-markup"
                        component={DealerMarkup}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>

                {/* Kupon */}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse-rise"/>
                            </div>
                            <h6 className="mt-5">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute
                        path="/kupon"
                        component={Kupon}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>

                {/*Cuanku*/}
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <div className="text-center">
                                <Loader type="ball-pulse-rise"/>
                            </div>
                            <h6 className="mt-5">
                                Please wait..
                                <small>PT Belanja Pasti Indonesia</small>
                            </h6>
                        </div>
                    </div>
                }>
                    <PrivateRoute
                        path="/cuanku"
                        component={Cuanku}
                        request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                </Suspense>

                <ToastContainer/>
            </Fragment>
        )
    }
};

const mapStateToProps = state => ({
    api_token: state.Auth.api_token,
    user: state.Auth.user
})



const mapDispatchToProps = dispatch => ({
    init_auth: (api_token, auth_key, data) => dispatch(init_auth(api_token, auth_key, data)),
    clear_auth: () => dispatch(clear_auth()),

})

export default connect(mapStateToProps, mapDispatchToProps)(AppMain)
